import { Select } from "antd";

export default function SortBy({
  isLoading,
  options,
  defaultValue,
  onChange,
}: {
  isLoading: boolean;
  options: Array<any>;
  defaultValue: string;
  onChange: any;
}) {
  return (
    <div className="inline-flex items-center text-base">
      <label htmlFor="sort_by" className="mr-2 hidden md:inline-block">
        Sort By:
      </label>
      <Select
        id="sort_by"
        className="min-w-[12rem]"
        options={options}
        defaultValue={defaultValue}
        onChange={onChange}
        disabled={isLoading}
      />
    </div>
  );
}
