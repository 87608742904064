export const isEven = (number: number) => number % 2 === 0;

export const getDiscountPercentage = (
  markedPrice: number,
  sellingPrice: number
) => {
  const discountPercentage = ((markedPrice - sellingPrice) / markedPrice) * 100;
  return Math.floor(discountPercentage).toFixed(0);
};


export const usNumberFormat = (number: number) => {
  if (+number || +number === 0) {
    return new Intl.NumberFormat("en-US").format(number);
  }
  return null;
};

export const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

export const getBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => resolve(reader.result);

    reader.onerror = (error) => reject(error);
  });

export const generateHierarchy = (
  items: any,
  titleKey: string,
  valueKey = "_id"
) => {
  if (items?.length > 0) {
    return items?.map((item: any) =>
      item?.child?.length > 0
        ? {
            title: item?.[titleKey],
            value: item?.[valueKey],
            children: generateHierarchy(item?.child, titleKey),
          }
        : {
            title: item?.[titleKey],
            value: item?.[valueKey],
          }
    );
  }

  return [];
};

export const getCombinations = (
  list: any,
  n = 0,
  result: any = [],
  current: any = []
) => {
  if (n === list.length) {
    result.push(current);
  } else {
    list[n].forEach((item: any) =>
      getCombinations(list, n + 1, result, [...current, item])
    );
  }
  return result;
};

export const getRandomIntInclusive = (min: number, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const isColorHexValid = (value: string) => {
  if (new RegExp(/^#([A-F0-9]{3}|[A-F0-9]{6})$/i).test(value)) {
    return true;
  }
  return false;
};

export const getInfiniteQueryCurrentLength = (data: any) => {
  let count = 0;

  if (data?.pages?.length > 0) {
    data?.pages?.forEach((page: any) => {
      if (page?.data?.docs?.length > 0) {
        count += page?.data?.docs?.length;
      }
    });
  }

  return count;
};
