import { useQuery } from "@tanstack/react-query";

import queryKeys from "constants/reactQuery";
import fetcher from "services/fetcher";

export default function useSiteSetting() {
  return useQuery(
    [queryKeys.shop.siteSetting],
    () => fetcher("/site-setting/site-setting"),
    {
      staleTime: 60000,
    }
  );
}
