import { Row, Col } from "antd";

export default function ProductsByCategorySkeleton({ count = 8 }) {
  return (
    <Row gutter={[24, 24]}>
      {Array.from(Array(count).keys()).map((item, index) => (
        <Col key={index} xs={24} sm={12} md={8} lg={6}>
          <div className="h-[22rem] animate-pulse rounded-lg bg-stone-400"></div>
        </Col>
      ))}
    </Row>
  );
}
