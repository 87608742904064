import { Modal, notification, message } from "antd";

const notifyError = (error: any) => {
  notification.destroy();
  return notification.error({
    message: error?.response?.data?.title || "Something went wrong!",
    description: error?.response?.data?.message || "",
  });
};

const notifyErrorMessage = (error: any) => {
  message.destroy();
  return message.error(
    error?.response?.data?.message || "Something went wrong!"
  );
};

export const handleError = (error: any) => {
  if (error?.response?.status === 401 || error?.response?.status === 404) {
    return;
  }

  // 440 status code is received if refresh token is invalid. Time to force user to login again
  if (error?.response?.status === 440) {
    return Modal.confirm({
      title: "Session Expired!",
      content: "Do you want to go to the login page?",
      okText: "Okay",
      // Remove cancelText to hide cancel button
      onOk: () => {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        window.location.href = "/login";
      },
      // Provide an empty function for onCancel to disable cancel action
      onCancel: () => {
        Modal.destroyAll();
      },
      // Optionally, you can also set maskClosable to false to prevent closing by clicking on the mask
      maskClosable: false,
    });
  }

  notifyError(error);
};

export const handleErrorMessage = (error: any) => {
  notifyErrorMessage(error);
};

export default handleError;
