export const groupifyFilters = (filtersObj: any = {}) => {
  let transformedFiltersObj: any = {};

  Object.keys(filtersObj)
    ?.filter((item) => (item?.includes("_hex") ? false : true))
    ?.forEach((item) => {
      if (item?.startsWith("age_")) {
        transformedFiltersObj["age"] = {
          ...transformedFiltersObj["age"],
          [item]: item,
        };
      } else if (item?.startsWith("benefits_")) {
        transformedFiltersObj["benefits"] = {
          ...transformedFiltersObj["benefits"],
          [item]: item,
        };
      } else if (item?.startsWith("color_")) {
        transformedFiltersObj["color"] = {
          ...transformedFiltersObj["color"],
          [item]: item,
        };
      } else if (item?.startsWith("coverage_")) {
        transformedFiltersObj["coverage"] = {
          ...transformedFiltersObj["coverage"],
          [item]: item,
        };
      } else if (item?.startsWith("finish_")) {
        transformedFiltersObj["finish"] = {
          ...transformedFiltersObj["finish"],
          [item]: item,
        };
      } else if (item?.startsWith("formulation_")) {
        transformedFiltersObj["formulation"] = {
          ...transformedFiltersObj["formulation"],
          [item]: item,
        };
      } else if (item?.startsWith("skin_type_")) {
        transformedFiltersObj["skin_type"] = {
          ...transformedFiltersObj["skin_type"],
          [item]: item,
        };
      } else {
        transformedFiltersObj["others"] = {
          ...transformedFiltersObj["others"],
          [item]: item,
        };
      }
    });

  return transformedFiltersObj;
};

export const getFilterLabel = (
  filterKey: string,
  filterGroup: string,
  filtersObject: any = {}
) => {
  let label = "";

  if (filterKey?.startsWith("age_")) {
    label = filterKey?.split("_").slice(-1)[0] + "+";
  } else {
    if (filterKey?.startsWith(filterGroup)) {
      label = filterKey?.slice(filterGroup.length)?.split("_")?.join(" ");
    }
  }

  return (
    <div className="inline-flex flex-row-reverse items-center gap-1 capitalize">
      <span className="inline-block">{label}</span>
      {filterKey?.startsWith("color_") && (
        <span
          className="inline-block h-5 w-5 rounded-full"
          style={{
            backgroundColor:
              filtersObject?.[filterKey + "_hex"] || "transparent",
          }}
        />
      )}
    </div>
  );
};
