const queryKeys = {
  auth: {
    user: "authUser",
    notifications: "authNotifications",
  },
  admin: {
    dashboard: "adminDashboard",
    sliders: "adminSliders",
    categories: "adminCategories",
    brands: "adminBrands",
    brandsForSelect: "adminBrandsForSelect",
    colors: "adminColors",
    deliveryAddresses: "adminDeliveryAddresses",
    vouchers: "adminVouchers",
    products: "adminProducts",
    product: "adminProduct",
    productFilters: "adminProductFilters",
    reviews: "adminReviews",
    review: "adminReview",
    orders: "adminOrders",
    order: "adminOrder",
    servicies: {
      servicies: "adminServicies",
      serviciesForSelect: "adminServiciesForSelect",
      serviciesForServicePage: "ServiciesForServicePage",
      ServiceForPublicBySlug:"ServiceForPublicBySlug",
      serviceForPublic:"SercieForPublic",
    },
    special: "adminSpecial",
    guest: {
      orders: "guestOrders",
      order: "guestOrder",
    },
    users: {
      users: "adminUsersUsers",

      admins: "adminUsersAdmins",
    },
    consent:"adminConsent",
    misc: {
      subscriber:"adminSubscriber",
      siteSetting: "adminSiteSetting",
      contactMessages: "adminContactMessages",
      
      pages: "adminPages",
      page: "adminPage",
      faqs: {
        faq: "adminFaqs",
        faqGroup: "adminFaqGroup",
      },
      career: "adminCareer",
      emailTemplates: "adminEmailTemplates",
      emailTemplate: "adminEmailTemplate",
      gallery: "adminGallery",
      location: {
        locations: "adminLocations",
        Filterlocations: "adminFilterLocations",

        locationForPublic: "locationForPublic",
        otherLocation:"otherLocation"
      },
    },
    aboutUs: {
      teams: "adminTeams",
      teamsPublic: "publicTeams",

      testimonials: "adminTestimonials",
      testimonialGroupByLocation:"testimonialGroupByLocation",
      testimonialForPublicHomePage:"testimonialForPublicHomePage",
      testimonialForPublicLocationPage:"testimonialForPublicLocationPage"

    },
    blogs: "adminBlogs",
    blog: "adminBlog",

    skinReports: "skinReports",
    skinReport: "skinReport",
  },

  shop: {
    sliders: "shopSliders",
    subscriber:"subscriber",

    testimonial:"shoptestimonial",
    categories: {
      categories: "shopCategories",
      topLevelCategories: "shopTopLevelCategories",
      productsByCategory: "shopProductsByCategory",
      productsByCategoryMeta: "productsByCategoryMeta",
    },
    brands: {
      brands: "shopBrands",
      productsByBrand: "shopProductsByBrand",
    },
    colors: "shopColors",
    productBySlug: "shopProductBySlug",
    productReviews: "shopProductReviews",
    searchedProducts: "shopSearchedProducts",
    recommendationProducts: "shopRecommendationProducts",
    deals: "shopDeals",
    dealsMeta: "shopDealsMeta",
    featured: "shopFeatured",
    nonBeauty:"nonBeauty",
    nonBeautyMeta:"nonBeautyMeta",
    featuredMeta: "shopFeaturedMeta",
    bestSeller: "shopBestSeller",
    bestSellerMeta: "shopBestSellerMeta",

    homeCategories: "shopHomeCategories",
    latestProducts: "shopLatestProducts",
    similarProducts: "shopSimilarProducts",
    addresses: "shopAddresses",
    siteSetting: "shopSiteSetting",
    page: "shopPage",
    faqs: "shopFaqs",
    blogs: "shopBlogs",
    blog: "shopBlog",
    teams: "shopTeams",
    testimonials: "shopTestimonials",
  },
  user: {
    cart: "userCart",
    wishlist: "userWishlist",
    orders: "userOrders",
    order: "userOrder",
    reviews: "userReviews",
    review: "userReview",
    gallery: "shopGallery",
  },

  booking: {
    booking: "booking",
    LocationForSelect:"LocationForSelect",
    ServiceForSelect:"ServiceForSelect",
    ServicePricingForSelect:"ServicePricingForSelect",


    specialbooking: "specialBooking",
  },
};

export default queryKeys;
