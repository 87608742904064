import { useQuery } from "@tanstack/react-query";

import queryKeys from "constants/reactQuery";
import fetcher from "services/fetcher";

export default function useBestSellerMeta() {
  return useQuery([queryKeys.shop.bestSellerMeta], () =>
    fetcher("/product/featured/filters")
  );
}
