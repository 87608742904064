import RangeSlider from "components/common/rangeSlider/RangeSlider";
import FilterOptions from "components/common/filterOptions/FilterOptions";
import ChildCategories from "./ChildCategories";
import ChildBrands from "./ChildBrands";

export default function DesktopFilters({
  isLoading,
  data,
  handlePriceRangeChange,
  handleFilterOptionChange,
}: {
  isLoading: boolean;
  data: any;
  handlePriceRangeChange: (range: any) => void;
  handleFilterOptionChange: (e: any) => void;
}) {
  return (
    <div className="sticky top-[10rem]">
      <RangeSlider
        isLoading={isLoading}
        productsByCategoryMetaData={data}
        onAfterChange={(range: any) => handlePriceRangeChange(range)}
        
      />
      {data?.data?.categories?.length > 0 && (
        <div className="mt-4">
          <ChildCategories childCategories={data?.data?.categories} />
        </div>
      )}
      {data?.data?.brands?.length > 0 && (
        <div className="mt-4">
          <ChildBrands childBrands={data?.data?.brands} />
        </div>
      )}
      <div className="mt-4">
        <FilterOptions
          isLoading={isLoading}
          data={data?.data?.filterOptions}
          onChange={handleFilterOptionChange}
        />
      </div>
    </div>
  );
}
