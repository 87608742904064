import * as React from "react";
import { Row, Col } from "antd";
import { Helmet } from "react-helmet-async";
import { MdOutlineFilterList } from "react-icons/md";

import useFeatured from "services/shop/featured/useFeatured";
import useFeaturedMeta from "services/shop/featured/useFeaturedMeta";
import ProductsByCategorySkeleton from "components/skeleton/productsByCategorySkeleton/ProductsByCategorySkeleton";
import ProductCard from "components/common/productCard/ProductCard";
import AutoLoadMore from "components/common/loadMore/AutoLoadMore";
import MobileFilters from "../productsByCategory/MobileFilters";
import DesktopFilters from "../productsByCategory/DesktopFilters";
import useBestSeller from "services/shop/bestSeller/useBestSeller";
import useBestSellerMeta from "services/shop/bestSeller/useBestSellerMeta";
import OurShopHeaderAdSection from "components/home/ads/OurShopHeaderAdSection";
import useSiteSetting from "services/shop/siteSetting/useSiteSetting";
import SortBy from "components/common/sortBy/SortBy";
const sortBys = [
  {
    label: "Default",
    value: JSON.stringify({}),
  },
  {
    label: "Price High to Low",
    value: JSON.stringify({
      sort: "price",
      dir: "desc",
    }),
  },
  {
    label: "Price Low to High",
    value: JSON.stringify({
      sort: "price",
      dir: "asc",
    }),
  },
  {
    label: "Latest First",
    value: JSON.stringify({
      sort: "createdAt",
      dir: "desc",
    }),
  },
  {
    label: "Oldest First",
    value: JSON.stringify({
      sort: "createdAt",
      dir: "asc",
    }),
  },
];
export default function BestSellerProducts() {
  const [queryParams, setQueryParams] = React.useState<any>({
    limit: 16,
    sort: undefined,
    dir: undefined,
    min: undefined,
    max: undefined,
    filters: undefined,
  });
  const [showMobileFilter, setShowMobileFilter] =
    React.useState<boolean>(false);

  const { isLoading, data, hasNextPage, fetchNextPage } =
    useBestSeller(queryParams);

  const { isLoading: featuredMetaLoading, data: featuredMetaData } =
    useBestSellerMeta();

  const toggleMobileFilter = () => setShowMobileFilter(!showMobileFilter);
  const { isLoading: siteSettingLoading, data: siteSettingData } =
    useSiteSetting();
  const handlePriceRangeChange = (value: any) => {
    setQueryParams({
      ...queryParams,
      min: value[0],
      max: value[1],
    });
  };
  const handleSortByChange = (value: any) =>
    setQueryParams({
      ...queryParams,
      ...JSON.parse(value),
    });

  const handleFilterOptionChange = (event: any) => {
    let targetFilters;

    if (!queryParams?.filters) {
      targetFilters = event.target.name;
    } else {
      const existingActiveFiltersArray = queryParams?.filters?.split(",");

      if (!existingActiveFiltersArray?.includes(event.target.name)) {
        existingActiveFiltersArray?.push(event.target.name);
        targetFilters = existingActiveFiltersArray?.join(",");
      } else {
        targetFilters =
          existingActiveFiltersArray
            ?.filter((item: any) => item !== event.target.name)
            ?.join(",") || undefined;
      }
    }

    setQueryParams({
      ...queryParams,
      filters: targetFilters,
    });
  };

  return (
    <div className="container py-6 md:py-8">
      {/* SEO start */}
      <Helmet>
        <title>Featured Products | EyeBrow</title>
        <meta name="description" content="Featured Products | EyeBrow" />
      </Helmet>
      {/* SEO end */}

      <MobileFilters
        showMobileFilter={showMobileFilter}
        toggleMobileFilter={toggleMobileFilter}
        isLoading={featuredMetaLoading}
        data={featuredMetaData}
        handlePriceRangeChange={handlePriceRangeChange}
        handleFilterOptionChange={handleFilterOptionChange}
      />
  <OurShopHeaderAdSection
        title="Best seller"
        siteSettingLoading={siteSettingLoading}
        siteSettingData={siteSettingData}
      />
      {/* Title sortby start */}
      <div className="flex flex-wrap items-center justify-between gap-x-4 gap-y-2 my-2 ">
        <h1 className="text-xl font-semibold">
        <button
            onClick={toggleMobileFilter}
            className="inline-flex items-center justify-center space-x-1 bg-transparent md:hidden"
          >
            <MdOutlineFilterList size={24} />
            <span className="text-sm leading-none">Filter</span>
          </button>        </h1>
        <div className="flex flex-wrap items-center justify-around gap-x-2 gap-y-2">
       
          <SortBy
            isLoading={isLoading}
            options={sortBys}
            defaultValue={"{}"}
            onChange={handleSortByChange}
          />
      
        </div>
      </div>
      {/* Title sortby end */}

      <div className="mt-6">
        <Row gutter={[24, 24]}>
          <Col xs={0} sm={0} md={7} lg={5}>
            <DesktopFilters
              isLoading={featuredMetaLoading}
              data={featuredMetaData}
              handlePriceRangeChange={handlePriceRangeChange}
              handleFilterOptionChange={handleFilterOptionChange}
            />
          </Col>

          {/* Products listing start */}
          <Col xs={24} sm={24} md={17} lg={19}>
            {isLoading && <ProductsByCategorySkeleton count={8} />}
            {data && (
              <AutoLoadMore
                dataLength={data?.pages?.reduce(
                  (acc: any, page: any) => acc + page?.data?.docs?.length,
                  0
                )}
                hasMore={hasNextPage || false}
                next={fetchNextPage}
              >
                <Row gutter={[24, 24]}>
                  {data?.pages?.[0]?.data?.docs?.length > 0 ? (
                    data?.pages?.map((page: any) =>
                      page?.data?.docs?.map((item: any, index: number) => (
                        <Col key={index} xs={12} sm={12} lg={8} xl={6}>
                          <ProductCard product={item} />
                        </Col>
                      ))
                    )
                  ) : (
                    <Col xs={24} sm={24}>
                      <p className="text-center text-base font-semibold text-neutral-700">
                        Sorry, No Products Found!
                      </p>
                    </Col>
                  )}
                </Row>
              </AutoLoadMore>
            )}
          </Col>
          {/* Products listing end */}
        </Row>
      </div>
    </div>
  );
}
