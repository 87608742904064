import * as React from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import Loader from "components/loading/loader/Loader";

type AutoLoadMoreProps = {
  dataLength: number;
  hasMore: boolean;
  next: () => void;
  children: React.ReactNode;
};

export default function AutoLoadMore({
  dataLength,
  hasMore,
  next,
  children,
}: AutoLoadMoreProps) {
  return (
    <InfiniteScroll
      dataLength={dataLength}
      hasMore={hasMore}
      next={next}
      loader={
        <div className="my-10 flex items-center justify-center gap-x-2 rounded-lg border p-4">
          <span className="inline-flex items-center justify-center">
            <Loader />
          </span>
          <span>Loading...</span>
        </div>
      }
      style={{
        overflow: "hidden",
      }}
    >
      {children}
    </InfiniteScroll>
  );
}
