import RangeSlider from "components/common/rangeSlider/RangeSlider";
import FilterOptions from "components/common/filterOptions/FilterOptions";
import ChildCategories from "./ChildCategories";
import ChildBrands from "./ChildBrands";

export default function MobileFilters({
  showMobileFilter,
  toggleMobileFilter,
  isLoading,
  data,
  handlePriceRangeChange,
  handleFilterOptionChange,
}: {
  showMobileFilter: boolean;
  toggleMobileFilter: () => void;
  isLoading: boolean;
  data: any;
  handlePriceRangeChange: (range: any) => void;
  handleFilterOptionChange: (e: any) => void;
}) {
  return (
    <div>
      <div
        onClick={toggleMobileFilter}
        className="fixed inset-0 z-[100] cursor-pointer bg-[rgba(0,0,0,0.6)] transition-all duration-500"
        style={{
          visibility: showMobileFilter ? "visible" : "hidden",
          opacity: showMobileFilter ? "1" : "0",
        }}
      />
      <div
        className="fixed top-0 right-0 bottom-0 z-[100] w-[calc(min(70%,20rem))] overflow-y-auto bg-white p-4 transition-transform duration-500"
        style={{
          transform: showMobileFilter ? "translateX(0)" : "translateX(100%)",
        }}
      >
        <RangeSlider
          isLoading={isLoading}
          productsByCategoryMetaData={data}
          onAfterChange={(range: any) => handlePriceRangeChange(range)}
        />
        {data?.data?.categories?.length > 0 && (
          <div className="mt-4">
            <ChildCategories childCategories={data?.data?.categories} />
          </div>
        )}
        {data?.data?.brands?.length > 0 && (
          <div className="mt-4">
            <ChildBrands childBrands={data?.data?.brands} />
          </div>
        )}
        <div className="mt-4">
          <FilterOptions
            isLoading={isLoading}
            data={data?.data?.filterOptions}
            onChange={handleFilterOptionChange}
          />
        </div>
      </div>
    </div>
  );
}
