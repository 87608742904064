import * as React from "react";
import { Slider } from "antd";

import TableSkeleton from "components/skeleton/tableSkeleton/TableSkeleton";

export default function RangeSlider({
  isLoading,
  productsByCategoryMetaData,
  onAfterChange,
}: {
  isLoading: boolean;
  productsByCategoryMetaData: any;
  onAfterChange: any;
}) {
  const [currentRange, setCurrentRange] = React.useState<[number, number]>([
    0, 0,
  ]);

  const min = productsByCategoryMetaData?.data?.priceRange?.min;
  const max = productsByCategoryMetaData?.data?.priceRange?.max;

  React.useEffect(() => {
    if (min && max) {
      setCurrentRange([min, max]);
    }
  }, [min, max]);

  const handleChange = (value: any) => {
    setCurrentRange(value);
  };

  return (
    <div>
      {isLoading && <TableSkeleton rows={1} />}
      {min && max && min !== max && (
        <div>
          <label
            htmlFor="range_slider"
            className="text-xl font-semibold text-neutral-600"
          >
            Price Range
          </label>
          <Slider
            id="range_slider"
            range={true}
            min={min}
            max={max}
            value={currentRange}
            onChange={handleChange}
            onAfterChange={onAfterChange}
      
            
          />
          <div className="flex space-x-3 text-sm text-neutral-600">
            <span>$ {currentRange?.[0]?.toLocaleString()}</span>
            <span>To</span>
            <span>$ {currentRange?.[1]?.toLocaleString()}</span>
          </div>
        </div>
      )}
    </div>
  );
}
