import { useInfiniteQuery } from "@tanstack/react-query";

import queryKeys from "constants/reactQuery";
import fetcher from "services/fetcher";

export default function useBestSeller(queryParams = {}) {
  const query = new URLSearchParams(
    JSON.parse(JSON.stringify(queryParams))
  ).toString();

  return useInfiniteQuery(
    [queryKeys.shop.bestSeller, queryParams],
    ({ pageParam = 1 }) =>
      fetcher(`/product/best-seller?page=${pageParam}&${query}`),
    {
      getNextPageParam: (lastPage) =>
        lastPage?.data?.pagination?.nextPage
          ? lastPage?.data?.pagination?.page + 1
          : undefined,
    }
  );
}
