import { Link, useNavigate } from "react-router-dom";
import { Rate } from "antd";
import { AiOutlineShopping } from "react-icons/ai";

import { usNumberFormat } from "utils";

type ProductCardProps = {
  product: any;
};

export default function   ProductCard({ product }: ProductCardProps) {
  const navigate = useNavigate();

  const handleAddToCart = async () => {
    navigate(`/product/${product?.slug}`);
  };

  return (
    <div>
      {/* Image start */}
      <Link
        to={`/product/${product?.slug}`}
        className="group relative block h-52 overflow-hidden rounded-t-lg border-primaryImageGrey bg-primaryImageGrey"
      >
        {product?.offPercent > 0 && (
          <div className="absolute z-10  px-2 py-1 text-white"  style={{
            backgroundColor:"#EBB9BA"
          }}>
            {product?.offPercent} %
          </div>
        )}
        <img
          src={product?.images?.[0]}
          alt=""
          loading="lazy"
          className="h-full w-full object-contain transition-[transform] duration-500 group-hover:scale-125"
        />
        <button
          onClick={handleAddToCart}
          className="absolute right-3 bottom-3 inline-flex h-10 w-10 items-center justify-center rounded-full text-white disabled:cursor-not-allowed"
          style={{
            backgroundColor:"#EBB9BA"
          }}
        >
          <AiOutlineShopping size={20} />
        </button>
      </Link>
      {/* Image end */}

      <div className="rounded-b-lg bg-[#faf7f7] p-3 shadow-md">
        {/* Title start */}
        <Link
          to={`/brand/${product?.brand?.slug}`}
          className="inline-block text-xs font-semibold uppercase text-neutral-500 hover:text-neutral-700 hover:underline"
        >
          {product?.brand?.name}
        </Link>
        <Link
          to={`/product/${product?.slug}`}
          className="mt-1.5 h-12 text-lg font-medium leading-6 text-neutral-800 line-clamp-2 hover:text-neutral-800 hover:underline"
        >
          {product?.title}
        </Link>
        <div className="mt-1.5 flex items-center gap-x-4 text-sm font-semibold text-neutral-800 md:text-lg">
          <span>$&nbsp;{usNumberFormat(product?.price)}</span>
          {product?.strikePrice && product?.strikePrice > product?.price && (
            <span className="text-neutral-500 line-through">
              $&nbsp;{usNumberFormat(product?.strikePrice)}
            </span>
          )}
        </div>
        {/* Title end */}

        {/* Rating start */}
        <div className="mt-1 mb-1 h-6">
          {product?.ratings > 0 && (
            <div className="flex flex-wrap items-center gap-x-2">
              <Rate
                disabled
                allowHalf
                defaultValue={product?.ratings}
                className="text-sm  md:text-base"
               
              />
              <span className="inline-block text-xs text-neutral-600 md:text-base">
                ({product?.ratings})
              </span>
            </div>
          )}
        </div>
        {/* Rating end */}
      </div>
    </div>
  );
}
