import * as React from "react";
import { Checkbox } from "antd";
import { RxCaretDown, RxCaretUp } from "react-icons/rx";

import TableSkeleton from "components/skeleton/tableSkeleton/TableSkeleton";
import { groupifyFilters, getFilterLabel } from "utils/filter";

export default function FilterOptions({
  isLoading,
  data,
  onChange,
}: {
  isLoading: boolean;
  data: any;
  onChange: any;
}) {
  const groupifiedData = data ? groupifyFilters(data) : {};

  return (
    <div>
      {isLoading && <TableSkeleton />}
      {data && (
        <div className="space-y-4">
          {Object.keys(groupifiedData)?.map((filterGroup: string) => (
            <div key={filterGroup}>
              <FilterOption
                filterGroup={filterGroup}
                data={data}
                groupifiedData={groupifiedData}
                onChange={onChange}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

function FilterOption({ filterGroup, data, groupifiedData, onChange }: any) {
  const ref = React.useRef<any>(null);
  const [toggle, setToggle] = React.useState(false);

  const handleViewMoreViewLess = () => setToggle(!toggle);

  return (
    <div>
      <p className="mb-1.5 border-b pb-0.5 text-lg font-medium capitalize">
        {filterGroup?.split("_")?.join(" ")}
      </p>
      {Object.keys(groupifiedData?.[filterGroup])?.length > 5 ? (
        <div>
          <div
            ref={ref}
            className="relative space-y-2 overflow-hidden transition-[height] duration-300"
            style={{
              height:
                toggle && ref?.current?.scrollHeight
                  ? ref?.current?.scrollHeight
                  : "7rem",
            }}
          >
            {Object.keys(groupifiedData?.[filterGroup])?.map(
              (filterKey: string) => (
                <div key={filterKey}>
                  <Checkbox name={filterKey} onChange={onChange}>
                    {getFilterLabel(filterKey, filterGroup, data)}
                  </Checkbox>
                </div>
              )
            )}
            {!toggle && (
              <span className="absolute left-0 right-0 bottom-0 inline-block h-8 bg-gradient-to-b from-transparent to-white" />
            )}
          </div>
          <button
            onClick={handleViewMoreViewLess}
            className="inline-flex items-center bg-transparent py-1 font-medium text-primary"
          >
            <span>{toggle ? "View Less" : "View More"}</span>
            <span className="inline-flex items-center justify-center">
              {toggle ? <RxCaretUp size={20} /> : <RxCaretDown size={20} />}
            </span>
          </button>
        </div>
      ) : (
        <div className="space-y-2">
          {Object.keys(groupifiedData?.[filterGroup])?.map(
            (filterKey: string) => (
              <div key={filterKey}>
                <Checkbox name={filterKey} onChange={onChange}>
                  {getFilterLabel(filterKey, filterGroup, data)}
                </Checkbox>
              </div>
            )
          )}
        </div>
      )}
    </div>
  );
}
