import { Breadcrumb, Skeleton } from "antd";
import { Link } from "react-router-dom";

type OurShopHeaderAdSectionProps = {
  siteSettingLoading: boolean;
  siteSettingData: any;
  title: string;
};

export default function OurShopHeaderAdSection({
  siteSettingLoading,
  siteSettingData,
  title,
}: OurShopHeaderAdSectionProps) {
  return (
    <div>
      <div className=" hidden justify-center md:block md:flex-nowrap ">
        {siteSettingLoading ? (
          <Skeleton.Image className="w-full md:w-1/2 xl:w-1/3 " active />
        ) : (
          siteSettingData?.data?.ourShopPageAds1 && (
            <a
              href={siteSettingData?.data?.ourShopPageAds1 || "/"}
              target="_blank"
              rel="noopener noreferrer"
              className="relative block w-full overflow-hidden md:h-[368px] xl:h-[368px] xxl:h-[368px]"
            >
              <img
                src={siteSettingData?.data?.ourShopPageAds1}
                alt=""
                loading="lazy"
                className="h-full w-full rounded-t-lg "
              />
              <div className="absolute top-1/2 left-2/3 -translate-x-1/2 -translate-y-1/2 !font-SeaweedScript font-normal text-black sm:text-[20px] md:text-[40px] lg:text-[68px]">
                {title}
                <div className="font-normal text-black sm:text-[5px] md:text-[15px] lg:text-[15px]">
                  For Forever Youthful skin
                </div>
              </div>
            </a>
          )
        )}
      </div>
      <div className=" md:hidden m-3">
      {siteSettingData?.data?.ourShopPageAds1 && (
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item>{title}</Breadcrumb.Item>
        </Breadcrumb>
      )}
        {/* Breadcrumb end */}
      </div>
    </div>
  );
}
