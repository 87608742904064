import { Link } from "react-router-dom";

export default function ChildBrands({
  childBrands,
}: {
  childBrands: Array<any>;
}) {
  if (!childBrands || childBrands?.length < 1) {
    return null;
  }

  return (
    <div>
      <p className="mb-1.5 border-b pb-0.5 text-lg font-medium capitalize">
        Related Brands
      </p>
      <div className="flex flex-wrap gap-2">
        {childBrands?.map((childBrand: any, index: number) => (
          <Link
            key={index}
            to={`/brand/${childBrand?.slug}`}
            className="inline-block  text-white bg-primary rounded-md border px-3 py-0.5  no-underline duration-500 hover:bg-transparent hover:border-primary hover:text-primary"
          >
            {childBrand?.name}
          </Link>
        ))}
      </div>
    </div>
  );
}
