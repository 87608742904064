import { Link } from "react-router-dom";

export default function ChildCategories({
  childCategories,
}: {
  childCategories: Array<any>;
}) {
  if (!childCategories || childCategories?.length < 1) {
    return null;
  }

  return (
    <div>
      <p className="mb-1.5 border-b pb-0.5 text-lg font-medium capitalize">
        Related Categories
      </p>
      <div className="flex flex-wrap gap-2">
        {childCategories?.map((childCategory: any, index: number) => (
          <Link
            key={index}
            to={`/category/${childCategory?.slug}`}
            className="inline-block text-white bg-primary rounded-md border px-3 py-0.5  no-underline  duration-500 hover:bg-transparent hover:text-primary hover:border-primary "
          >
            {childCategory?.title}
          </Link>
        ))}
      </div>
    </div>
  );
}
